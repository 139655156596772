import { defineStore } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import type { ServiceResponse } from "~/types/api/ServiceResponse";
import type { SlotsResponse } from "~/types/api/Slots";
import type { SchedulerService } from "~/types/SchedulerService";
import type { Slot } from "~/types/Slot";

export const useSchedulerStore = defineStore("schedulerStore", () => {
  const from = new Date(Date.now());

  async function fetch<T>(url: string): Promise<T> {
    return $fetch<T>(url);
  }

  async function fetchSlots({ service, user }: { service: number; user?: number }): Promise<Ref<SlotsResponse> | null> {
    const fetchedSlots = await fetchSlotsFromDateAndMoreIfNoneFound(from, service, user);

    if (fetchedSlots === null) {
      return null;
    }

    return ref(fetchedSlots.data);
  }

  async function getSlots({ service, user }: { service: number; user?: number }) {
    if (import.meta.server) {
      return null;
    }

    return await fetchSlots({ service, user });
  }

  async function getNextSlotForHealthProfessional({
    service,
    user,
  }: {
    service: number;
    user: number;
  }): Promise<Slot | null> {
    if (import.meta.server) {
      return null;
    }

    const fetchedSlots = await fetchSlots({ service, user });

    if (fetchedSlots === null) {
      return null;
    }

    return fetchedSlots.value.slots[0];
  }

  async function fetchService(id: number): Promise<Ref<SchedulerService> | null> {
    if (id <= 0) {
      return null;
    }

    const params = new URLSearchParams({
      id: id.toString(10),
    });

    const response = await fetch<ServiceResponse | null>(`/api/scheduler/services?${params.toString()}`).catch(
      () => null,
    );

    if (response === null) {
      return null;
    }

    return ref(response.services[0]);
  }

  async function getService(id: number) {
    if (import.meta.server) {
      return null;
    }

    return await fetchService(id);
  }

  return {
    getNextSlotForHealthProfessional,
    getService,
    getSlots,
  };
});
